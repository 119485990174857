import ClientLayout from "./components/layout/ClientLayout";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import PostsClient from "./components/posts/PostsClient";
import AboutUs from "./pages/AboutUs";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import Pagar from "./pages/Pagar";
import Contact from "./pages/Contact";
import Documentation from "./pages/Documentation";
import Jobs from "./components/jobs/Jobs";
import TanksClient from "./components/tanks/TanksClient";
import GradientesClient from "./components/quiebraGradientes/GradientesClient";

import { ToastContainer } from "react-toastify";
/* FRAMER MOTION */
import { AnimatePresence } from "framer-motion";
import Gallery from "./pages/Gallery";
import LayoutProvider from "./components/layout/LayoutProvider";
import NotFound from "./components/shared/NotFound";
import PreguntasFrecuentes from "./components/servicios/PreguntasFrecuentes";
import TerminosYCondiciones from "./components/servicios/TerminosYCondiciones";
import ContratoDePrivacidad from "./components/servicios/ContratoDePrivacidad";
import Historia from "./pages/sections/AboutUs/Historia"; 
import PozosClient from "./components/pozos/PozosClient";

function App() {
  const location = useLocation();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LayoutProvider>
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<ClientLayout />}>
              <Route index element={<Home />} />
              <Route path="nosotros" element={<AboutUs />} />
              <Route path="historia" element={<Historia />} />
              <Route path="publicaciones" element={<PostsClient />} />
              <Route path="proyectos" element={<Projects />} />
              <Route path="servicios" element={<Services />} />
              <Route path="servicios/pagar" element={<Pagar />} />
              <Route path="faq" element={<PreguntasFrecuentes />} />
              <Route path="terminos-y-condiciones" element={<TerminosYCondiciones />} />
              <Route path="privacidad" element={<ContratoDePrivacidad />} />
              <Route path="contacto" element={<Contact />} />
              <Route path="contacto/curriculum" element={<Jobs />} />
              <Route path="documentacion" element={<Documentation />} />
              <Route path="tanques" element={<TanksClient />} />
              <Route path="quiebraGradientes" element={<GradientesClient />} />
              <Route path="pozos" element={<PozosClient />} />
              <Route path="galeria" element={<Gallery />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </AnimatePresence>
      </LayoutProvider>
    </>
  );
}

export default App;
